import './App.css';
import { Fragment, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Landing } from './components/landing/landing'
import { GeneralNav } from './components/nav/general'
import { Pricing } from './components/landing/pricing'
import { checkSession, tokensExist } from './api/auth/session'
import { Dashboard } from './components/dashboard/dashboard'

function App() {
  const publicPaths = ['/', '/pricing']

  if (!publicPaths.includes(window.location.pathname)){
    checkSession()
  }
  const hasTokens = tokensExist(false, true)
  const [loggedIn, setLoggedIn] = useState(hasTokens)

  return (
    <Fragment>
      <Router>
        {/* Not Logged In Nav */}
        {!loggedIn && <GeneralNav props={{setLoggedIn}} />}

        {/* Landing Page or User Settings if signed in */}
        <Route path="/" exact component={loggedIn ? Dashboard : Landing} /> 
        <Route path="/pricing" exact component={Pricing} />

        {/* Signed in */}
        <Route path="/dashboard" exact component={loggedIn ? Dashboard : Landing} />
      </Router>
    </Fragment>
  );
}

export default App;
