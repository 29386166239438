import { People, Settings, AssignmentInd, History, Dashboard } from '@material-ui/icons'
import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from 'react-bootstrap'
import { getSettings } from '../../api/fxaccounts/settings'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles({
    navTile: {
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    }
})

export const LeftNav = ({socket, selectedPage, setSelectedPage}) => {
    

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '10vh', marginBottom: '20px'}}>
                <Button style={{padding: '10px 15px 10px 15px', fontWeight: 'bold'}}>New Trade &nbsp;&nbsp;+</Button>
            </div>
            <NavTab
                Icon={Dashboard}
                text="Dashboard"
                selected={selectedPage}
                selector={() => {setSelectedPage("Dashboard");}}
            />
            <NavTab
                Icon={People}
                text="Accounts"
                selected={selectedPage}
                selector={setSelectedPage}
            />
            <NavTab
                Icon={AssignmentInd}
                text="Licenses"
                selected={selectedPage}
                selector={setSelectedPage}
            />
            <NavTab
                Icon={Settings}
                text="Settings"
                selected={selectedPage}
                selector={() => {setSelectedPage("Settings"); getSettings(socket);}}
            />
            <NavTab
                Icon={History}
                text="Order History"
                selected={selectedPage}
                selector={setSelectedPage}
            />
        </div>
    )
}

const NavTab = ({Icon, text, selected, selector}) => {
    
    const isSmall = useMediaQuery('(max-width:525px)')
    const classes = useStyles()
    return (
        <div className={classes.navTile} style={{display: 'flex', fontWeight: 'bold', padding: '15px', cursor: 'pointer'}} onClick={selector}>
            {isSmall
            ?   <Icon />
            :   <Fragment>
                    <div style={{width: '40%', textAlign: 'center'}}>
                        <Icon />
                    </div>
                    <div style={{width: '60%'}}>
                        {text}
                    </div>
                </Fragment>
            }
        </div>
    )
}