import React, { useState} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Nav, Navbar } from 'react-bootstrap'
import { Login } from './login';
import { CSSTransition } from 'react-transition-group'
import '../../App.css'

export const GeneralNav = ({props}) => {
    
    const isBig = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(max-width:525px)');

    const [showLogin, setShowLogin] = useState(false)

    const duration = 300
    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
    }    
    const transitionStyles = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
    }

    return (
        <div style={{display: 'flex', position: 'absolute', flexDirection: 'column'}}>
            <Navbar expand="lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.7)', width: '100vw'}}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{textAlign: 'center'}}>
                    <Nav className="mr-auto" style={{marginLeft: isBig ? '9vw' : isSmall ? '0px' : 'auto'}}>
                        <Nav.Link href="/">HOME</Nav.Link>                        
                        <Nav.Link onClick={() => setShowLogin(!showLogin)}>SIGN IN</Nav.Link>
                        <Nav.Link onClick={() => setShowLogin(false)}>SIGN UP</Nav.Link>
                        {/* <Nav.Link href="/pricing">PRICING</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {/* {showLogin && <Login />} */}
            <CSSTransition in={showLogin} timeout={duration}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }}>
                        <Login props={{setLoggedIn: props.setLoggedIn}} />
                        <div style={{height: '5vh', backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0))'}}></div>
                    </div>
                )}
            </CSSTransition>
            {/* <Slide direction="down" in={showLogin}>
                <Login />
            </Slide> */}
        </div>
    )
}