
export const getSettings = (socket) => {
    if (!socket || socket.readyState !== WebSocket.OPEN){
        return
    }
    socket.send('Settings')
}

export const loadSettings = (data, props) => {
    if (!props.setAccountSettings){
        return
    }
    props.setAccountSettings(data)
}