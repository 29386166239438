import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const LandingCard = ({Icon, header, text}) => {

    const isSmall = useMediaQuery('(max-width:700px)');

    return (
        <div style={{width: isSmall ? '80vw' : '25vw', textAlign: 'center', marginTop: '45px'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{borderRadius: '100px', boxShadow: '0 0 8px 1px black', padding: '8px', backgroundColor: '#efebeb'}}>
                    <Icon style={{fontSize: '4rem', color: '#5cc6d0'}} />
                </div>
            </div>
            <h2>{header}</h2>
            <div>
                {text}
            </div>
        </div>
    )
}