import { getTokensSyncNoUpdate } from '../auth/session'
import { baseWS } from '../base'

import { loadSettings } from './settings'

const controller = `${baseWS}/fxaccounts/v1`

export const connectToAccountWS = (props) => {
    const tokens = getTokensSyncNoUpdate()
    let socket = new WebSocket(controller)
    socket.onopen = (e) => {
        socket.send(`AccessToken:${tokens.AccessToken}`)
    }
    socket.onmessage = (e) => handleReceive(e, props)
    return socket
}

const handleReceive = (event, props) => {
    if (event.data === 'Success!'){
        return
    }
    const message = JSON.parse(event.data)
    switch (message.Source){
        case 'SETTINGS':
            loadSettings(message, props)
            break
    }
}