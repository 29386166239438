import { signInAPI } from './auth'
import { handleErrors } from '../base'
import { startSession, setTokens } from '../../api/auth/session'

export const loginAPI = async (email, password, onSuccess) => {
    const response = await signInAPI(email, password)
    const results = await handleErrors(response)

    // Any additional token refreshes go here
    if (results && results.IdToken){
        results.Email = email
        setTokens(results, true)
        startSession()
        onSuccess && onSuccess()
    }
    return results
}