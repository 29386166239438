import { People, SwapHoriz, AttachMoney, TrendingUp } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Dashcard } from './dashcard'
import { LeftNav } from './leftnav'
import { TopNav } from './topnav'
import { Row, Col } from 'react-bootstrap'
import { connectToAccountWS } from '../../api/fxaccounts/socket'
import { Settings } from './settings'
import { loadStripe } from '@stripe/stripe-js'
import { stripePublicKey } from '../../api/base'

export const Dashboard = () => {
    const [accountSocket, setAccountSocket] = useState(null)
    const [selectedPage, setSelectedPage] = useState('Dashboard')
    
    const [stripePromise, setStripePromise] = useState(null)

    const [accountSettings, setAccountSettings] = useState({})

    useEffect(() => {

        const props = {
            setAccountSettings
        }

        if (accountSocket == null){
            setAccountSocket(connectToAccountWS(props))
        }
        setStripePromise(loadStripe(stripePublicKey))
    }, [])

    return (
        <div>
            <div>
                <TopNav />
            </div>
            <div style={{display: 'flex'}}>
                <div style={{width: '15vw', height: '100vh', backgroundColor: '#eeeeee'}}>
                    <LeftNav socket={accountSocket} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
                </div>
                <div style={{width: '85vw', height: '100vh', backgroundColor: '#e4e5e8', padding: '10vh 3vw 3vh 3vw'}}>
                {selectedPage === 'Dashboard' && (
                    <Row>
                        <Dashcard 
                            Icon={People}
                            description="Accounts"
                            number="17"
                            bgColor="#efebeb"
                            color="#5cc6d0"
                        />
                        <Dashcard 
                            Icon={SwapHoriz}
                            description="Open Trades"
                            number="153"
                            bgColor="#efebeb"
                            color="purple"
                        />
                        <Dashcard 
                            Icon={TrendingUp}
                            description="Trade Profit"
                            number="43512"
                            bgColor="#efebeb"
                            color="green"
                        />                 
                        <Dashcard 
                            Icon={AttachMoney}
                            description="Free Margin"
                            number="173256"
                            bgColor="#efebeb"
                            color="orange"
                        />
                    </Row>
                )}
                {selectedPage === 'Settings' && (
                    <Settings accountSettings={accountSettings} stripePromise={stripePromise} />
                )}
                </div>
            </div>
        </div>
    )
}