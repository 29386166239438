import React, {useState, useEffect} from 'react'
import { Col } from 'react-bootstrap'

export const Dashcard = ({Icon, number, description, color, bgColor}) => {

    return (
        <Col xl={3} lg={6} sm={10} style={{marginBottom: '15px'}}>
        <div style={{boxShadow: '#cac7c7 0px 0px 5px 1px', borderRadius: '6px', padding: '15px', backgroundColor: '#eeeeee'}}>
            <div style={{display: 'flex'}}>
                <div style={{borderRadius: '100px', boxShadow: '#827f7f 0px 0px 5px 1px', padding: '8px', backgroundColor: bgColor, transform: 'scale(0.8)', marginRight: '20px'}}>
                    <Icon style={{fontSize: '4rem', color: color}} />
                </div>
                <div>
            <h2>{number}</h2>
            <h5>{description}</h5>
            </div>
            </div>
        </div>
        </Col>
    )
}