import React, {useState, useEffect, Fragment} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Row, Col, Button, FormControl } from 'react-bootstrap'
import { CheckOutForm } from '../stripe/checkOutForm'
import { Elements } from '@stripe/react-stripe-js'

export const Settings = ({accountSettings, stripePromise, socket}) => {
    const [newPayMethod, setNewPayMethod] = useState(null)


    return (
        <Fragment>
            <Row style={{marginBottom: '5vh'}}>
                <Col></Col>
                <Col lg={10} sm={12}>
                    <div style={{boxShadow: '#cac7c7 0px 0px 5px 1px', borderRadius: '6px', padding: '15px', backgroundColor: '#eeeeee'}}>
                        <h2 style={{marginBottom: '20px', textAlign: 'center'}}>Payment Source</h2>
                        <div style={{textAlign: 'center'}}>
                            {accountSettings.DefaultSource === null
                                ? <h4>Not On File</h4>
                                : <h4>[ENDING]</h4>
                            }
                        </div>
                    </div>
                </Col>
                <Col></Col>
            </Row>
            <Row style={{marginBottom: '5vh'}}>
                <Col></Col>
                <Col lg={10} sm={12}>
                    <div style={{boxShadow: '#cac7c7 0px 0px 5px 1px', borderRadius: '6px', padding: '20px', backgroundColor: '#eeeeee'}}>
                        <h2 style={{marginBottom: '20px', textAlign: 'center'}}>Update Payment Source</h2>
                        <Row>
                            <Col></Col>
                            <Col lg={7} sm={12}>
                                <Elements stripe={stripePromise}>
                                    <CheckOutForm setPaymentMethod={setNewPayMethod} buttonText="Update" />
                                </Elements>
                            </Col>
                            <Col></Col>
                        </Row>
                        <div style={{display: 'flex'}}>
                        </div>
                    </div>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col lg={5} sm={12} style={{marginBottom: '5vh'}}>
                    <div style={{boxShadow: '#cac7c7 0px 0px 5px 1px', borderRadius: '6px', padding: '15px', backgroundColor: '#eeeeee'}}>
                            <h2 style={{marginBottom: '20px', textAlign: 'center'}}>Can Manage Others</h2>
                            <div style={{marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px'}}>
                                Some sort of disclaimer here saying that they are managing the risk of others and we are in no way liable if they mess up other peoples accounts.
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button>I Accept</Button>
                            </div>
                    </div>
                </Col>
                <Col lg={5} sm={12}>
                    <div style={{boxShadow: '#cac7c7 0px 0px 5px 1px', borderRadius: '6px', padding: '15px', backgroundColor: '#eeeeee'}}>
                            <h2 style={{marginBottom: '20px', textAlign: 'center'}}>Manager Code</h2>
                            <div>
                                <FormControl disabled={true} value="564as6d5465-465451-461651" />
                            </div>
                    </div>
                </Col>
                <Col></Col>
            </Row>
        </Fragment>
    )
}