import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import background1 from '../../images/landing/background1.jpeg'
import background2 from '../../images/landing/background2.jpg'
import varbisfull from '../../images/varbis/varbisfull.png'
import ExploreIcon from '@material-ui/icons/Explore'
import PeopleIcon from '@material-ui/icons/People'
import BusinessIcon from '@material-ui/icons/Business'
import {LandingCard} from './landingcard'

export const Landing = () => {
    
    const isBig = useMediaQuery('(min-width:1200px)')
    const isSmall = useMediaQuery('(max-width:525px)')

    return (
        <div>
            <div style={{backgroundColor: '#0b092e'}}>
                <div style={{width: '100%', height: '65vh', backgroundSize: 'contain', backgroundImage: `url(${background2})`,
                    backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', marginBottom: '3vh', boxShadow: '0 0 20px 1px black'
                }}>              
                    <div style={{display: 'flex', height: '100%', justifyContent: 'center', flexDirection: 'column', width: isBig ? '810px' : '100vw', alignItems: 'center', backgroundColor: (isBig || isSmall) ? 'initial' : 'rgba(0,0,0,0.6)'}}>
                        <div style={{marginTop: !isBig ? '30vh' : '0px'}}>
                            <div>
                                <img src={varbisfull} style={{paddingLeft: '30px', paddingRight: '30px', width: '100%'}} />
                            </div>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                                <span style={{color: 'white', borderColor: 'white', borderWidth: 'medium', borderStyle: 'solid', borderRadius: '8px', padding: '8px 18px', boxShadow: '0px 0px 4px 0px white'}}>
                                    <h1 style={{fontSize: '5rem'}}>FOREX</h1>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                    <LandingCard
                        Icon={ExploreIcon}
                        header="Unlimited Choices"
                        text="Our Platform communicates directly with MetaTrader 4 servers to allow you to place trades through a huge number of forex brokers. Works with ANY Forex server that runs through MetaTrader 4."
                    />
                    <LandingCard
                        Icon={PeopleIcon}
                        header="Multiple Accounts"
                        text="Place trades across multiple accounts in multiple brokers within seconds all with varying lot sizes. Group your accounts and target groups for fast trades."
                    />
                    <LandingCard
                        Icon={BusinessIcon}
                        header="Managing Services"
                        text="Manage thousands of clients from a single platform without having to sign out and back in hundreds of times. No more copying the trade or broadcasting your secrets to the world."
                    />
                </div>
            </div>
        </div>
    )
}