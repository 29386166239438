import React, { useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { loginAPI } from '../../api/auth/login'
import { Redirect } from 'react-router-dom'


export const Login = ({props}) => {
    
    const isBig = useMediaQuery('(min-width:1200px)')
    const isSmall = useMediaQuery('(max-width:525px)')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState('')
    const [message, setMessage] = useState('')
    const [signedIn, setSignedIn] = useState(false)

    const signin = async (e) => {
        e.preventDefault()
        setLoading(true)
        setMessage('')
        let msg
        try{
            const tokens = await loginAPI(email, password, () => {props.setLoggedIn(true); window.location.href = '/dashboard'})
        }
        catch (error){
            try{error = await error}catch(err){}
            msg = error
        }
        if (msg){
            // Notify
            if (msg){
                console.log(msg)
                if (msg.message === 'Failed to fetch' || msg === '503'){
                    msg = 'Currently Under Maintenance'
                }
                setMessage(msg.toString())
            }
        }
        setLoading(false)
    }

    if (signedIn){
        return <Redirect to="/dashboard" />
    }

    return (
        <div style={{display: 'flex', backgroundColor: 'rgba(255, 255, 255, 0.7)', justifyContent: 'center'}}>
            <Form style={{width: isBig ? '20vw' : isSmall ? '80vw' : '50vw'}} onSubmit={signin}>
                <Form.Group as={Row}>
                    <Form.Label column xs={3} style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Email</Form.Label>
                    <Col>
                        <Form.Control required={true} type="email" onChange={(e) => setEmail(e.target.value)}></Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column xs={3} style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Password</Form.Label>
                    <Col>
                        <Form.Control required={true} type="password" onChange={(e) => setPassword(e.target.value)}></Form.Control>
                    </Col>
                </Form.Group>
                {message && (
                    <Form.Group as={Row} style={{justifyContent: 'center'}}>
                        <Form.Label style={{fontSize: '1.2rem', color: 'red', fontWeight: 'bold'}}>{message}</Form.Label>
                    </Form.Group>
                )}
                <Form.Group as={Row} style={{justifyContent: 'center'}}>
                    <Button variant="primary" type="submit">Submit</Button>
                </Form.Group>
            </Form>
        </div>
    )
}