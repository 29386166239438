import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import background1 from '../../images/landing/background1.jpeg'
import background2 from '../../images/landing/background2.jpg'
import varbisfull from '../../images/varbis/varbisfull.png'

export const Pricing = () => {
    
    const isBig = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(max-width:525px)');

    return (
        <div>
            <div style={{backgroundColor: '#0b092e'}}>
                <div style={{width: '100%', height: '65vh', backgroundSize: 'contain', backgroundImage: `url(${background2})`,
                    backgroundRepeat: 'no-repeat', backgroundPositionX: '100%', marginBottom: '3vh', boxShadow: '0 0 20px 1px black'
                }}>              
                    <div style={{display: 'flex', height: '100%', justifyContent: 'center', flexDirection: 'column', width: isBig ? '810px' : '100vw', alignItems: 'center', backgroundColor: (isBig || isSmall) ? 'initial' : 'rgba(0,0,0,0.6)'}}>
                        <div style={{marginTop: !isBig ? '30vh' : '0px'}}>
                            <div>
                                <img src={varbisfull} style={{paddingLeft: '30px', paddingRight: '30px', width: '100%'}} />
                            </div>
                            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
                                <span style={{color: 'white', borderColor: 'white', borderWidth: 'medium', borderStyle: 'solid', borderRadius: '8px', padding: '8px 18px', boxShadow: '0px 0px 4px 0px white'}}>
                                    <h1 style={{fontSize: '5rem'}}>FOREX</h1>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                   <h1>Pricing</h1>
                </div>
            </div>
        </div>
    )
}