// export const baseURL = `http://localhost:61939`
// export const baseURL = `https://localhost:44337`
export const baseURL = `https://api.varbis.net`

// export const baseWS = 'ws://localhost:61939'
export const baseWS = `wss://api.varbis.net`

export const stripePublicKey = 'pk_live_51GwWoEJ4I7MVNjyq0hqDzkgnR8SLvtk4oX5ZC3rTu3qEg3aEQpfv9XjXofx5qaNZwonUJZzOVJ3YoxbXRpDvra9s00P0h6hF4f'

export function handleErrors(response) {
    if (response.status === 401 || response.status === 404 || response.status === 503){
     throw Error(response.status)
    }
    if (!response.ok) {
      throw response.json()
    }
    if (response.status === 204){
      return {}
    }
    return response.json()
}