import {CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import React, { useState, useEffect, useCallback, Fragment } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Container, Row, Col, Card, ListGroup, Table, Form, FormControl, Button, Modal, Alert } from 'react-bootstrap'

export const CheckOutForm = ({setPaymentMethod, buttonText}) => {
    const stripe = useStripe()
    const elements = useElements()

    const [name, setName] = useState('')
    const [msg, setMsg] = useState(null)
    const [showError, setShowError] = useState(false)
    const loadBig = window.innerWidth > 900

    const CARD_OPTIONS = {
        iconStyle: 'solid',
        style: {
          base: {
            iconColor: 'black',
            color: 'black',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '18px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {color: 'blue'},
            '::placeholder': {color: 'gray'},
          },
          invalid: {
            iconColor: 'darkred',
            color: 'red',
          },
        },
      };


    const handleSubmit = async (event) => {
        event.preventDefault()

        if (!stripe || !elements){
            // No Stripe.JS loaded yet
            return
        }

        const cardElement = elements.getElement(loadBig ? CardElement : CardNumberElement)

        const {error, paymentMethod} = await stripe.createPaymentMethod(({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: name
            }
        }))

        if (error){
            console.log('Stripe Error', error);
            setMsg(error.message)
            setShowError(true)
        }
        else{
            console.log('PaymentMethod', paymentMethod)
            setShowError(false)
            setMsg('')
            setPaymentMethod && setPaymentMethod(paymentMethod)
        }
    }

    return (        
        <form onSubmit={handleSubmit}>
            <Alert variant="danger" show={showError} onClose={() => setShowError(false)} dismissible>
                <Alert.Heading>Error!</Alert.Heading>
                <p>{msg}</p>
            </Alert>

            <Form>
                <Form.Group as={Row} style={{textAlign: loadBig ? 'end' : 'left'}}>
                    <Col sm={0} lg={1}></Col>
                    <Form.Label column sm={8} lg={3}>Name On Card</Form.Label>
                    <Col sm={8} lg={4}>
                        <Form.Control value={name} onChange={(e) => {setName(e.target.value)}} />
                    </Col>
                </Form.Group>
            </Form>    

            {loadBig 
            ? <CardElement options={CARD_OPTIONS} />
            : <Fragment>
                <div>
                    <Form.Label>Card Number</Form.Label>
                    <CardNumberElement options={CARD_OPTIONS} />
                </div>
                <div>
                    <Form.Label>Card Expiration</Form.Label>
                    <CardExpiryElement options={CARD_OPTIONS} />
                </div>
                <div>
                    <Form.Label>Card CVC</Form.Label>
                    <CardCvcElement options={CARD_OPTIONS} />
                </div>
            </Fragment> }

            <div style={{display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                <Button variant={!stripe || !name ? 'danger' : 'dark'} type="submit" disabled={!stripe || !name}>
                    {buttonText || 'Validate Card'}
                </Button>
            </div>
        </form>
    )
}