import React, {useState, useEffect} from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Nav, Navbar } from 'react-bootstrap'
import { getTokens, clearSession } from '../../api/auth/session'
import varbisfull from '../../images/varbis/varbisfull.png'

export const TopNav = () => {

    const [email, setEmail] = useState('')

    useEffect(() => {
        getTokens()
            .then((tokens) => {
                if (tokens){
                    setEmail(tokens.Email)
                }
            })
    })
    
    const isBig = useMediaQuery('(min-width:1200px)');
    const isSmall = useMediaQuery('(max-width:990px)');
    return (
        <div style={{display: 'flex', position: 'absolute', flexDirection: 'column', top: '0px', zIndex: '99'}}>
            <Navbar expand="lg" style={{backgroundColor: 'rgba(255, 255, 255, 0.5)', width: '100vw'}}>
                {!isSmall && <div style={{ width: '15vw', textAlign: 'center', backgroundImage: `url(${varbisfull})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', top: '5px', height: '4vh'}}>
                </div>}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isSmall && <Nav><Nav.Link style={{fontSize: '1.1rem'}}>{email}</Nav.Link></Nav> }
                <Navbar.Collapse id="basic-navbar-nav" style={{textAlign: 'center'}}>
                    <Nav className="ml-auto">
                        {!isSmall && <Nav.Link style={{fontSize: '1.1rem'}}>{email}</Nav.Link>  } 
                        <Nav.Link onClick={() => clearSession(true)} style={{fontSize: '1.1rem'}}>Log Out</Nav.Link>                    
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}